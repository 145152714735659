<template>
  <div class=" merch-card">
    <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="轮播图设置" name="first">
      <banner></banner>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import banner from "./components/banner.vue";
export default {
  components: { banner },
  name: "activityAudit",
  data() {
    return {
      activeName: 'first'
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    ...commonIndex.mapMutations(["changeSeach"]),
    handleClick(tab, event) {
        console.log(tab, event);
      }
  },
};
</script>
<style lang="scss" scoped>
</style>
