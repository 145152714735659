<template>
  <div>
    <div class="add" @click="openAdd()">新 建</div>
    <el-table
      border
      :data="banner.list"
      style="width: 100%"
      :header-cell-style="{ background: '#F2F3F5' }"
      height="600"
    >
      <el-table-column align="center" label="序号" width="70" type="index">
      </el-table-column>
      <el-table-column
        align="center"
        label="轮播图"
        prop="re_status"
        width="600"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 355px; height: 150px"
            :src="scope.row.bannerUrl"
            :preview-src-list="scope.row.bannerUrlList"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column align="center" label="跳转类型" prop="re_status">
        <template slot-scope="scope">
          <div>
            {{ scope.row.jumpType | jumpType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="导航页类型" prop="re_status">
        <template slot-scope="scope">
          <div>
            {{ scope.row.navigationType | navigationType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" prop="re_status" width="200">
        <template slot-scope="scope">
          <div class="operation">
            <div @click="openAdd(scope.row)" class="cur">编辑</div>
            <div @click="del(scope.row)" class="cur">删除</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="merch-page pb20">
      <el-pagination
        background
        @current-change="bannerCurrentChange"
        :page-size="10"
        :current-page="currPage"
        layout="total, prev, pager, next, jumper"
        :total="banner.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="bankAddType ? '新建轮播' : '编辑轮播'"
      :visible.sync="islogistics"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="560px"
    >
      <div class="bank-mess">
        <!-- <div class="bank-list">
          <span>*</span><span class="lable">跳转类型</span>
          <el-select v-model="AddData.jumpType" placeholder="请选择" disabled>
            <el-option
              v-for="item in jumpType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="bank-list">
          <span>*</span><span class="lable">导航页类型</span>
          <el-select
            v-model="AddData.navigationType"
            placeholder="请选择"
            disabled
          >
            <el-option
              v-for="item in navigationType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->

        <div class="bank-list">
          <span></span><span class="lable">展示时间</span>
          <el-date-picker
            v-model="datelist"
            type="datetimerange"
            range-separator="至"
            start-placeholder="请选择开始日期"
            end-placeholder="请选择结束日期"
            unlink-panels
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          />
        </div>

        <div class="bank-list">
          <span>*</span><span class="lable">轮播图片</span>
          <div>
            <div class="cover-box">
              <el-upload
                :action="Actions"
                list-type="picture-card"
                :on-remove="handleRemove"
                :limit="1"
                class="{disabled: uploadDisabled}"
                :file-list="fileList"
                :on-success="handleAvatarSuccess"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <div class="cover"></div>
            </div>
          </div>
        </div>
        <div
          class="bank-list"
          v-if="AddData.jumpType == '1' && AddData.navigationType == '2'"
        >
          <span>*</span><span class="lable">商品名称</span>
          <div class="goodsName">
            <!-- {{ AddData.goodsName }} -->
            {{ AddData.bannerGoodsRelation[0].goodsName }}
            <div @click="innerVisible = true">打开选择商品弹弹窗</div>
          </div>
        </div>
      </div>

      <el-dialog
        width="1200px"
        title="选择商品"
        :visible.sync="innerVisible"
        append-to-body
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <div class="goods-search">
          <div class="search1">
            <el-select
              clearable
              v-model="formApi.scope"
              placeholder="选择经营类别"
              filterable
              style="width: 250px"
            >
              <el-option
                v-for="item in BasissCopeData.notNewbusinessScopeId"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="search1">
            <el-cascader
              style="width: 250px"
              placeholder="选择商品分类"
              v-model="cascaderValue"
              :options="BasissCopeData.inquiregoods_categoryList"
              :props="propsData"
              @change="cascaderhandleChange"
              clearable
            ></el-cascader>
          </div>
          <div class="search1">
            <el-input
              style="width: 250px"
              placeholder="请输入商品名称/通用名"
              v-model="formApi.keyWords"
              clearable
            ></el-input>
          </div>
          <div class="search1">
            <el-input
              style="width: 250px"
              placeholder="请输入生产厂家"
              v-model="formApi.factory"
              clearable
            ></el-input>
          </div>
          <div @click="toSearch" class="toSearch">搜索</div>
        </div>
        <el-table :data="dataList.data" border style="width: 100%" height="500">
          <el-table-column prop="address" label="商品名称" align="center">
            <template slot-scope="{ row }">
              <el-tooltip effect="dark" :content="row.name" placement="bottom">
                <div class="exceed">{{ row.name }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="经营类别" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                effect="dark"
                :content="row.scope_string"
                placement="bottom"
              >
                <div class="exceed">{{ row.scope_string || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="商品分类" align="center">
            <template slot-scope="{ row }">
              <el-tooltip placement="top">
                <div slot="content">
                  <span v-for="(v, i) in row.category_string" :key="i"
                    >{{ v }}<br
                  /></span>
                </div>
                <div class="exceed">
                  {{ row.category_string[0] || "/" }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="address" label="商品分类" align="center">
              <template slot-scope="{ row }">
                <el-tooltip
                  effect="dark"
                  :content="row.category_string"
                  placement="bottom"
                >
                  <div class="exceed">{{ row.category_string || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column> -->
          <el-table-column prop="address" label="规格" align="center">
            <template slot-scope="{ row }">
              <div class="exceed">{{ row.specification }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="生产厂家" align="center">
            <template slot-scope="{ row }">
              <el-tooltip
                effect="dark"
                :content="row.manufacturer"
                placement="bottom"
              >
                <div class="exceed">{{ row.manufacturer || "/" }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="商品编号"
            align="center"
            width="100"
          >
            <template slot-scope="{ row }">
              <el-tooltip
                effect="dark"
                :content="row.goods_id + ''"
                placement="bottom"
              >
                <div class="exceed">{{ row.goods_id }}</div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="address"
            label="操作"
            align="center"
            width="100"
          >
            <template slot-scope="{ row }">
              <div @click="choseGoods(row)" class="cur">选择</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="merch-page pb20">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="dataList.per_page"
            :current-page="formApi.page"
            layout="total, prev, pager, next, jumper"
            :total="dataList.total"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="afiyAdd" v-if="bankAddType"
          >确认新建</el-button
        >
        <el-button type="primary" @click="afiyAdd" v-if="!bankAddType"
          >保 存</el-button
        >
        <el-button @click="islogistics = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="删除轮播图" :visible.sync="delDialog" width="30%">
      <span>确定删除当前轮播图</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="delDialog = false">取 消</el-button>
        <el-button type="primary" @click="cimdel()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions } = createNamespacedHelpers("Basics"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const Goods = createNamespacedHelpers("Goods");
import { javawholeUrl } from "@/api/javaUrl"; //引入接口
export default {
  name: "activityAudit",
  data() {
    return {
      datelist: [],
      jumpType: [
        {
          value: 1,
          label: "内部",
        },
      ],
      navigationType: [
        {
          value: 1,
          label: "商品列表",
        },
        {
          value: 2,
          label: "商品详情",
        },
        {
          value: 3,
          label: "加入购物车",
        },
      ],
      banner: {},
      bankAddType: true,
      islogistics: false,
      Actions: javawholeUrl + "/ptuser/common/upload",
      imageUrl: "",
      fileList: [{ url: "" }], //控制上传按钮,
      AddData: {
        bannerUrl: "",
        jumpType: "1",
        navigationType: "2",
        endTime: "",
        startTime: "",
        // goodsName: "未选择商品",
        // bannerGoodsRelation: {
        //   goodsId: null,
        // },
        bannerGoodsRelation: [
          {
            goodsId: null,
            goodsName: "未选择商品",
          },
        ],
      },
      innerVisible: false,
      formApi: {
        page: 1,
      },
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
      },
      cascaderValue: [],
      dataList: {},
      currPage: 1,
      delDialog: false,
      bannerId: null,
    };
  },
  filters: {
    jumpType(v) {
      switch (v) {
        case 1:
          return "内部";
      }
    },
    navigationType(v) {
      switch (v) {
        case 1:
          return "商品列表";
        case 2:
          return "商品详情";
        case 3:
          return "加入购物车";
      }
    },
  },
  created() {
    this.postbannerSelectBannerApi();
  },

  computed: {
    ...commonIndex.mapState(["BasissCopeData"]),
  },
  methods: {
    ...mapActions([
      "postbannerSelectBanner",
      "postbannerInsertBanner",
      "postbannerUpdateBanner",
      "postbannerDeleteBanner",
    ]),
    ...Goods.mapActions(["getbaseGoodslist"]),
    del(val) {
      this.bannerId = val.id;
      this.delDialog = true;
    },
    async cimdel() {
      let res = await this.postbannerDeleteBanner({ id: this.bannerId });
      if (res.code == "000000") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.delDialog = false;
        this.postbannerSelectBannerApi();
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    choseGoods(val) {
      // this.AddData.goodsName = val.name;
      this.AddData.bannerGoodsRelation[0].goodsId = val.goods_id;
      this.AddData.bannerGoodsRelation[0].goodsName = val.name;
      this.innerVisible = false;
    },
    async getbaseGoodslistApi() {
      let data = await this.getbaseGoodslist(this.formApi);
      if ((data.code = 200)) {
        data.data.data.forEach((v) => {
          let categoryArr = [];
          v.category_string.forEach((v1) => {
            let category = "";
            if (v1.length > 0) {
              v1.forEach((v2, i2) => {
                if (i2 == v1.length || i2 == 0) {
                  category = category + v2;
                } else {
                  category = category + ">" + v2;
                }
              });
            }
            categoryArr.push(category);
          });
          v.category_string = categoryArr;
        });
        this.dataList = data.data;
      }
    },
    async handleCurrentChange(e) {
      this.formApi.page = e;
      this.getbaseGoodslistApi();
    },

    async toSearch() {
      this.formApi.page = 1;
      this.getbaseGoodslistApi();
    },
    cascaderhandleChange(value) {
      this.cascaderValue = value;
      this.formApi.categoryId = value[value.length - 1];
    },
    async afiyAdd() {
      let endTime = "";
      let startTime = "";
      let res = null;
      if (this.datelist != null && this.datelist.length > 0) {
        endTime = this.datelist[0];
        startTime = this.datelist[1];
      } else {
        endTime = "";
        startTime = "";
      }
      let params = this.AddData;
      params.bannerUrl = this.imageUrl;
      params.endTime = endTime;
      params.startTime = startTime;
      if (params.bannerUrl == "") {
        this.$message({
          message: "轮播图片不能为空",
          type: "warning",
        });
        return;
      }
      if (
        params.jumpType == 1 &&
        params.navigationType == 2 &&
        params.bannerGoodsRelation[0].goodsId == null
      ) {
        this.$message({
          message: "未关联轮播商品",
          type: "warning",
        });
        return;
      }
      if (this.bankAddType) {
        res = await this.postbannerInsertBanner(params);
      } else {
        res = await this.postbannerUpdateBanner(params);
      }
      if (res.code == "000000") {
        (this.islogistics = false), this.postbannerSelectBannerApi();
        this.$message({
          message: "操作成功",
          type: "success",
        });
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.content.url;
    },
    // 打开新建弹框
    openAdd(row) {
      console.log(
        "%c [ row ]-543",
        "font-size:13px; background:pink; color:#bf2c9f;",
        row
      );
      if (!row) {
        this.datelist = null;
        this.fileList = [];
        this.imageUrl = "";
        this.AddData = {
          bannerUrl: "",
          jumpType: 1,
          navigationType: 2,
          endTime: "",
          startTime: "",
          // goodsName: "未选择商品",
          // bannerGoodsRelation: {
          //   goodsId: null,
          // },
          bannerGoodsRelation: [{ goodsId: null, goodsName: "未选择商品" }],
        };
        // this.bankAddType = true;
        // this.islogistics = true;
      } else {
        let startTime = this.$publicFunction.timestamp(row.startTime);
        let endTime = this.$publicFunction.timestamp(row.endTime);
        this.datelist = [startTime, endTime];
        this.fileList = [{ url: row.bannerUrl }];
        this.imageUrl = row.bannerUrl;
        this.AddData = {
          id: row.id,
          bannerUrl: row.bannerUrl,
          jumpType: row.jumpType,
          bannerGoodsRelation: [
            {
              goodsId: "",
              goodsName: "",
            },
          ],
          navigationType: row.navigationType,
          endTime: endTime,
          startTime: startTime,
        };
        if (row.bannerGoodsRelation) {
          this.AddData.bannerGoodsRelation[0].goodsName =
            row.bannerGoodsRelation[0].goodsName;
          this.AddData.bannerGoodsRelation[0].goodsId =
            row.bannerGoodsRelation[0].goodsId;
        }
        this.bankAddType = false;
        // row = JSON.parse(JSON.stringify(row));
        // this.AddData = row;
        // this.fileList = [{ url: row.bankPicture }];
        // this.imageUrl = row.bankPicture;
        // this.fileList[0].url=row.bankPicture;
      }
      (this.formApi = {
        page: 1,
        scope: "",
        keyWords: "",
        factory: "",
      }),
        (this.cascaderValue = []);
      this.getbaseGoodslistApi();

      this.islogistics = true;
    },
    handleRemove(file, fileList) {
      this.imageUrl = "";
    },
    bannerCurrentChange(e) {
      this.currPage = e;
      this.postbannerSelectBannerApi();
    },
    async postbannerSelectBannerApi() {
      let res = await this.postbannerSelectBanner({
        salesmanId: 0,
        currPage: this.currPage,
      });
      if (res.code == "000000") {
        res.content.list.forEach((item) => {
          item.bannerUrlList = [item.bannerUrl];
        });
        this.banner = res.content;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.add {
  width: 80px;
  height: 32px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #06b7ae;
  cursor: pointer;
}
.bank-mess {
  .bank-list {
    display: flex;
    // align-items: center;
    margin-top: 20px;
    span {
      color: #f00;
      line-height: 40px;
      width: 8px;
      display: inline-block;
    }
    .lable {
      font-size: 15px;
      min-width: 90px;
      color: #333;
      margin-left: 5px;
    }
    .upload-explain {
      margin-top: 10px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
  .bank-details {
    margin-top: 10px;
    display: flex;
    // height: 32px;
    line-height: 32px;
    color: #666;
    .lable {
      min-width: 95px;
      margin-right: 20px;
      color: #333;
      // text-align-last:  justify;
    }
    img {
      width: 50px;
      height: 50px;
    }
  }
}
.cover-box {
  position: relative;
}
.cover {
  width: 80px;
  height: 80px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 88px;
}
::v-deep .el-upload--picture-card {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 80px;
  height: 80px;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
::v-deep .el-date-editor .el-range-separator {
  width: 32px;
}
.goodsName {
  display: flex;
  align-items: center;
  > div {
    background-color: #06b7ae;
    width: 150px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    margin-left: 10px;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
  }
}
.goods-search {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px;
  .toSearch {
    height: 40px;
    line-height: 40px;
    width: 80px;
    background-color: #06b7ae;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
  }
  // >div{
  //   margin-right: 20px;
  // }
}
.cur {
  color: #06b7ae;
  cursor: pointer;
}
.pb20 {
  padding-bottom: 10px;
}
.operation {
  display: flex;
  justify-content: center;
  > div {
    margin: 0 10px;
  }
}
</style>
